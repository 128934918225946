$(document).on('turbolinks:load', function() {

    jquery('.create_event_form').validate({
      rules: {
        'event[name]': {
          required: true,
          notblank: true
        },
        'event[event_date]': {
          required: true,
          notblank: true,
          futureDate: true
        }
      },
      errorPlacement: function(error, element) {
        element.closest('.form-group').append(error)
      }
    })

    jquery.validator.addMethod('futureDate', function (value, element) {
      return moment(value).format('MM/DD/YYYY') >= moment().format('MM/DD/YYYY')
    }, 'The event date should be today or in the future.')

    $(document).off('click', "td[class^=td_status_]").on('click', "td[class^=td_status_]", function() {
      var type = $(this).data('type') || 'event'
      if ($(this).find(`[id^="status_select_tag_"]`).hasClass('d-none')){
        $(this).find(`[id^="status_select_tag_"]`).removeClass('d-none');
        $(this).find(`[id^="${type}_status_"]`).addClass('d-none');
      } else {
        $(this).find(`[id^="status_select_tag_"]`).addClass('d-none');
        $(this).find(`[id^="${type}_status_"]`).removeClass('d-none');
      }
    });

    $(document).on('click', "select[id^=select_status_]", e => {
      e.stopPropagation()
    })

    $(document).off('change', 'select[id^=select_status_]').on('change', "select[id^=select_status_]", function() {
      var selected_text =  $(this).find('option:selected').text();
      var status = 'Pending';
      var id  = $(this).data('id');
      var name  = $(this).data('name')
      var pervious_status = $(this).data('status')
      var type = $(this).data('type') || 'events';
      var moduleType = $(this).closest('td[class^=td_status]').data('type') || 'event';
      var status_value = $(this).data('status-id')
      var selected_val =  $(this).val();
      switch (selected_text) {
        case "Approved":
          status = "Approve";
          break;
        case "Rejected":
          status = "Reject";
          break;
        case "Inactive":
          status = "Inactive";
          break;
        case "Active":
          status = "Active";
          break;
      }
      var success = confirm(`Are you sure you want to make ${name} ${status} ?`);
      if(success)
      {
        $.ajax({
          url: `/admin/${type}/${id}/update_status`,
          type: 'get',
          data: {status: selected_val, id: id},
          success:function(response){
            $(`[id^="status_select_tag_${id}"]`).addClass('d-none');
            $(`[id^="${moduleType}_status_${id}"]`).removeClass('d-none');
            $(`.labelname_${id}`).text(selected_text);
          }
        })
      }
      else
      {
        $(`[id^="status_select_tag_${id}"] option[value= ${status_value}]`).prop('selected',true);
        $(`[id^="status_select_tag_${id}"]`).addClass('d-none');
        $(`[id^="${moduleType}_status_${id}"]`).removeClass('d-none');
        $(`.labelname_${id}`).text(pervious_status);
      }
  });
  $('[name="event[location]"]').selectpicker('destroy')
  $('[name="event[location]"]').autoComplete({
    bootstrapVersion: '4',
    minLength: 2,
    resolverSettings: {
      url: '/cities/filter?type=select',
      fail: () => {}
    }
  })
});
