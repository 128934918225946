$(document).on('turbolinks:load', function () {
    $('form.edit_user').submit(e => {
        let skillsSelect = $('[name="user[skill][]"]')
        if (skillsSelect.length) {
            let selectedSkills = skillsSelect.val()
            let selectedSkillLevels = $('[name^="user[skill_level]"]').filter((key, item) => $(item).val().trim() !== '')
            if (selectedSkills.length !== selectedSkillLevels.length) {
                $('a[data-tab="settings"]')[0].click()
                Swal.fire({
                    icon: 'error',
                    title: 'Please select skill level for all selected skills!',
                    showConfirmButton: false,
                    timer: 3000
                })
                return false
            }
        }
    })

    $('.selectpicker').each((index, item) => {
        $(item).selectpicker('destroy');
        $(item).selectpicker('refresh');
    });

    $(document).on('click', '.delete_item', e => {
        e.preventDefault()
        let $this = $(e.currentTarget)

        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            icon: 'question'
        }).then((result) => {
            if (result.isConfirmed) {
                $.ajax({
                    type: 'DELETE',
                    url: $this.attr('href'),
                    dataType: 'json',
                    success: data => {
                        if (data.status) {
                            $('#profile_completion_percentage_wrapper').html(data.html);
                            $this.closest('.item_container').fadeOut(function() {
                                $(this).removeClass('d-block')
                                Swal.fire({
                                    icon: 'success',
                                    title: data.message,
                                    showConfirmButton: false,
                                    timer: 3000
                                })
                            })
                        }
                    },
                    error: () => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Something went wrong',
                            showConfirmButton: false,
                            timer: 3000
                        })
                    }
                })
            }
        })
    });

    (() => {
        const parent = $('#projectModal')

        parent.find('#projectOngoingCheckbox').on('change', e => {
            let $this = $(e.currentTarget)
            parent.find('#projectEndDate').attr('disabled', $this.is(':checked'))
        })

        parent.find('#projectCodeAccess').on('change', e => {
            let $this = $(e.currentTarget)
            parent.find('#projectCodeLink').attr('disabled', $this.val() != 1)
        })

        parent.find('#projectSkills, #projectLanguages').on('keyup', e => {
            let $this = $(e.currentTarget)
            let type = $this.data('type')
            let data = $this.data(type) || []
            if (e.which == 13) {
                if($this.val().trim()) {
                    data.push($this.val().trim())
                    data = data.filter((v, i, a) => a.indexOf(v) === i)
                    $this.data(type, data).attr(`data-${type}`, data)

                    let tag = ''
                    for(let i = 0; i < data.length; i++) {
                        tag += `<div class="personal-profile__options-item mr-2 mb-2 mb-lg-0">
                                  <span>${data[i]}</span>

                                  <a href="#" data-index="${i}" data-type="${type}" class="ml-1 delete-${type}">
                                    <img width="12" height="12" src="/assets/profile_personal_info/delete.svg">
                                  </a>
                                </div>`;
                    }
                    parent.find(`#${type}-here`).html(tag)
                }
                $this.val('')
                e.preventDefault()
                return false
            }
        })

        parent.on('click', '.delete-skills, .delete-languages', e => {
            e.preventDefault()
            let $this = $(e.currentTarget)

            let type = $this.data('type')
            let text = $this.siblings('span').text().trim()
            let data = parent.find(`input[data-type="${type}"]`).data(type) || []
            data = data.filter(val => val !== text)

            $this.closest('div').remove()
            parent.find(`input[data-type="${type}"]`).data(type, data).attr(`data-${type}`, data)
        })

        parent.find('#saveProject').on('click', e => {
            e.preventDefault()

            let $this = $(e.currentTarget)

            let errors = []
            let project = new FormData()
            let uid = $(e.currentTarget).data('uid')
            if (!uid) {
                Swal.fire({
                    icon: 'error',
                    title: 'Please create a user before adding project!',
                    showConfirmButton: false,
                    timer: 3000
                })
                return false
            }
            project.append('authenticity_token', $('meta[name=csrf-token]').attr('content'))
            project.append('project[project_title]', parent.find('#projectTitle').val())
            project.append('project[user_id]', uid)

            if (!parent.find('#projectTitle').val().trim()) {
                errors.push({ field: 'projectTitle', message: 'The Project Title field is required.' });
            }
            project.append('project[description]', window["project_description_editor"].getData())
            if (parent.find('#projectImage')[0].files.length) {
                project.append('project[picture_attributes][image]', parent.find('#projectImage')[0].files[0])
            }
            project.append('project[ongoing]', (parent.find('#projectOngoingCheckbox').is(':checked') ? 1 : 0))
            project.append('project[start_date]', parent.find('#projectStartDate').val())
            if (parent.find('#projectStartDate').val().trim() === '') {
                errors.push({ field: 'projectStartDate', message: 'The Start Date field is required.' });
            }
            project.append('project[end_date]', parent.find('#projectEndDate').val())
            parent.find('input.projectPlatform:checked').each((k, v) => {
                project.append('project[project_platforms][platform][]', v.value)
            })
            project.append('project[code_access]', parent.find('#projectCodeAccess').val())
            project.append('project[link]', parent.find('#projectCodeLink').val())
            let skills =  parent.find('#projectSkills').data('skills') || []
            $.each(skills, (k, v) => {
                project.append('project[project_skills][skill][]', v)
            })

            let categories =  parent.find('#projectCategories').val()
            $.each(categories, (k, v) => {
                project.append('project[project_categories][category][]', v)
            })

            let languages =  parent.find('#projectLanguages').data('languages') || []
            $.each(languages, (k, v) => {
                project.append('project[project_languages][language][]', v)
            })

            if (errors.length) {
                const errorSpans = {
                    'projectTitle': $('.title_error'),
                    'projectStartDate': $('.date_error')
                };

                $.each(errorSpans, function (key, span) {
                    span.html('');
                });

                errors.forEach(function (error) {
                    const field = error.field;
                    const message = error.message;
                    const span = errorSpans[field];
                    if (span) {
                        span.html(message + '<br>');
                    }
                });
            } else {
                $this.text('Saving...').prop('disabled', true)
                $.ajax({
                    url: '/settings/add-project',
                    type: 'post',
                    data: project,
                    dataType: 'json',
                    cache: false,
                    processData: false,
                    contentType: false,
                    always: () => {},
                    success: data => {
                        Swal.fire({
                            title: 'Success!',
                            text: data.message,
                            icon: 'success',
                            timer: 3000,
                            timerProgressBar: true,
                        }).then(result => {
                            window.location.reload()
                        })
                    },
                    complete: () => {
                        $this.text('Save').prop('disabled', false)
                    },
                    error: error => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Something went wrong',
                            icon: 'error'
                        })
                    }
                })
            }
        })
    })();

    (() => {
        const parent = $('#experienceModal')

        parent.find('#jobOngoingCheckbox').on('change', e => {
            let $this = $(e.currentTarget)
            parent.find('#jobEndDate').attr('disabled', $this.is(':checked'))
        })

        parent.find('#saveJob').on('click', e => {
            e.preventDefault()

            let errors = []
            let experience = new FormData()
            let uid = $(e.currentTarget).data('uid')
            if (!uid) {
                Swal.fire({
                    icon: 'error',
                    title: 'Please create a user before adding experience!',
                    showConfirmButton: false,
                    timer: 3000
                })
                return false
            }
            experience.append('authenticity_token', $('meta[name=csrf-token]').attr('content'))
            experience.append('experience[job_title]', parent.find('#jobTitle').val())
            experience.append('experience[job_type]', parent.find('#jobType').val())

            if (!parent.find('#jobTitle').val().trim()) {
                errors.push('The Job Title field is required.')
            }
            if (!parent.find('#jobType').val().trim()) {
                errors.push('The Job Type field is required.')
            }
            if (!parent.find('#jobStartDate').val().trim()) {
                errors.push('The Start Date field is required.')
            }
            if (parent.find('#jobImage')[0].files.length) {
                experience.append('experience[picture_attributes][image]', parent.find('#jobImage')[0].files[0])
            }
            experience.append('experience[employer_name]', parent.find('#jobEmployerName').val())
            experience.append('experience[ongoing]', (parent.find('#jobOngoingCheckbox').is(':checked') ? 1 : 0))
            experience.append('experience[start_date]', parent.find('#jobStartDate').val())
            experience.append('experience[end_date]', parent.find('#jobEndDate').val())

            if (errors.length) {
                Swal.fire({
                    title: 'Error!',
                    html: errors.join('<br>'),
                    icon: 'error'
                })
            } else {
                $.ajax({
                    url: '/settings/add-experience',
                    type: 'post',
                    data: experience,
                    dataType: 'json',
                    cache: false,
                    processData: false,
                    contentType: false,
                    always: () => {},
                    success: data => {
                        Swal.fire({
                            title: 'Success!',
                            text: data.message,
                            icon: 'success',
                            timer: 3000,
                            timerProgressBar: true,
                        }).then(result => {
                            window.location.reload()
                        })
                    },
                    error: error => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Something went wrong',
                            icon: 'error'
                        })
                    }
                })
            }
        })
    })();

    var data = {
        age: '#age_privacy',
        gender: '#gender_privacy',
        location: '#location_privacy',
        name: '#name_privacy',
        email: '#email_privacy',
        photo: '#profile_photo_privacy',
    };

    function clickEventForRadiaBtn(data, key){
        const element = data[key];
        const buttonSelector = `a.only_me_link.${key}`;
        $(element).on('click', 'input[type="radio"]' , function() {
            let btn_img_src = $(this).siblings().find('img.d-none').attr('src');
            let btn_text = $(this).siblings().find('.audience-item__title').text();
            $(`${buttonSelector} img`).attr('src', btn_img_src);
            $(`${buttonSelector} img`).attr('height','30');
            $(`${buttonSelector} img`).attr('width','30');
            $(`${buttonSelector} span`).text(btn_text);
            $(`${buttonSelector} input[type=hidden]`).val($(this).val());
        });
    }

    for (var key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            clickEventForRadiaBtn(data, key);
        }
    }

    $(document).ready(function() {
        $("#jobLocation").selectpicker('destroy')
        $("#jobLocation").autoComplete({
            bootstrapVersion: '4',
            minLength: 2,
            resolverSettings: {
                url: '/cities/filter?type=select',
                fail: () => {}
            }
        })
    });

    $('[name="user[location]"]').autoComplete({
        bootstrapVersion: '4',
        minLength: 2,
        resolverSettings: {
            url: '/cities/filter?type=select',
            fail: () => {}
        }
    })

    $(document).ready(function (){
        var defaultValue = $('#user_location').data('value');
        if (defaultValue) {
          $('[name="user[location]_text"]').autoComplete('set', { value: defaultValue, text: defaultValue })
        }
    })
});
