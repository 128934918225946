$(document).on('turbolinks:load', function() {
  var participant_ids = [];
  var participant_id = [];

  $('body').on('click', '.select-direct-participant', function(e){
    e.preventDefault();
    participant_id.push($(this).attr('id'));
    $('.direct_participant_select').addClass('d-none');
    $('.direct_participant_message').removeClass('d-none');
  });

  $(document).on('click', '.conversation_list_item', function(e){
    e.preventDefault();
    var conversation_id = $(this).data('conversation-id');
    $.ajax({
      url: "/conversations/" + conversation_id + "/load_conversation",
      type: "GET",
      dataType: 'script',
    });
  });

  $('body').on('keyup', '.search_conversation', function(e){
    e.preventDefault();
    if (event.key === "Enter") {
      return;
    }
    $.ajax({
      url: '/conversations/search_conversation',
      type: "GET",
      dataType: 'script',
      data: {search: $(this).val(), popup: $(this).data('popup')},
    });
  });

  $(document).on('click', '#create_direct_chat', function(e){
    e.preventDefault();
    var body = $('#direct_message').val();
    var user_id = $(this).data("user-id");
    if (body === "" || body.replace(/\s+/g, '').length === 0) {
      alert("Type the contact's name to find them and start a conversation.");
      return;
    }
    $.ajax({
      url: '/messages',
      type: "POST",
      data: {participant_id: participant_id[0], message: {body: body, user_id: user_id}},
    }).done(function(){
      window.location.href = "/conversations";
    });
  });

  $('body').on('click', '.edit_link', function(e){
    $(this).closest('.dropdown').toggleClass("show");
  });

  $('body').on('keyup', '.friends_search', (e)=>{
    $.ajax({
      url: '/conversations/filter',
      type: 'GET',
      dataType: 'script',
      data: {search: $(e.target).val()}
    })
  })

  $('body').on('keyup', '.group_members_search', (e)=>{
    $.ajax({
      url: '/conversations/search_group_members',
      type: 'GET',
      dataType: 'script',
      data: {search: $(e.target).val(), group_slug: $("#group_slug").val()}
    })
  })

})
