import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.min.css'
$(document).on('turbolinks:load', function() {
  $('.select2_school').select2({
    tags: false,
    placeholder: "Select your School",
  });

  jquery('#new_company_user').validate({
    // ignore: ".ignore",
    rules: {
      'user[first_name]': {
        required: true,
        notblank: true,
        minlength: 1,
        maxlength: 50
      },
      'user[last_name]': {
        required: true,
        notblank: true,
        minlength: 1,
        maxlength: 50
      },
      'user[password]': {
        required: true,
        notblank: true,
        minlength: 6,
        maxlength: 50
      },
      'user[password_confirmation]': {
        required: true,
        notblank: true,
        minlength: 6,
        maxlength: 50,
        equalTo: "#pwd"
      },
      'user[email]': {
        required: true,
        isEmail: true,
        email: true,
        remote: {
          url: '/email_exist',
          sendParam: 'email',
          method: 'POST',
        }
      },
      'user[invitation_email]': {
        isEmail: true,
        email: true,
        remote: {
          url: '/email_exist',
          sendParam: 'email',
          method: 'POST',
        }
      },
      'user[organization_name]': {
        required: true,
        notblank: true,
        minlength: 1,
        maxlength: 50
      },
      'user[organization_type]': {
        required: true
      }
    },
    messages: {
      'user[email]': {
        remote: 'Email already exist.'
      },
      'user[invitation_email]': {
        remote: 'Email already exist.'
      }
    },
    errorPlacement: function(error, element) {
      error.insertAfter(element.parent());
    }
  });

  jquery.validator.addMethod("notblank", function(value) {
    return (value !== '' && value.trim() !== '');
  }, "This field can't be blank.");

  jquery.validator.addMethod("isEmail", function(value) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(value);
  }, "Email is not valid.");

  // $(document).on('click', '.company_signup_next', function(e){
  function hasInvitationToken() {
    var url = window.location.href;
    return url.indexOf('token=') !== -1;
  }
  $(".company_signup_next").unbind().click(function(e) {
    e.preventDefault();
    if (jquery('#new_company_user').valid()) {
      if (hasInvitationToken()) {
        $('#user_invitation_email').val('');
        var form = document.getElementById('new_company_user');
        form.submit();
      }
      else {
      var user_fullname = $("input[name='user[first_name]").val() + $("input[name='user[last_name]").val();
      var user_email    = $("input[name='user[email]").val();
      $('.company_signup').addClass('hidden');
      $('.org_reg_top_section').addClass('hidden');
      $('.company_type').removeClass('hidden');
      $("#user_fullname").val(user_fullname);
      $("#user_email").val(user_email);
      }
      // $('.company_invitation').removeClass('hidden');
    }
  });
  $('.organization_type_radio').click(function() {
    var user_fullname = $("#user_fullname").val();
    var user_email    = $("#user_email").val();
    $('.company_signup, .company_type').addClass('hidden');
    $('.org_reg_top_section').removeClass('hidden');

    var organization = $('.organization_type_radio:checked').val();

    if (organization === 'companies') {
      $('.company_invitation').removeClass('hidden');
    } else {
      $('.school_find').removeClass('hidden');
      $("input[name='school_attributes[user_name]").val(user_fullname).prop('disabled', true);
      $("input[name='school_attributes[user_email]").val(user_email).prop('disabled', true);
    }
  });

  $('#school').on('change', function() {
    $("input[name='school_attributes[name]']").val(null);
  });

  $('#new_school_form_btn').click(function(e){
    if ($('#school').val() !== ''){
      $('#school').val(null).trigger("change"); ;
      $('#school').attr('title', 'Select your School');
    }
  });

  $('.roles_onboard_modal_btn').click(function(e){
    e.preventDefault();
    var $requiredFields = $('.required-field');
    var isValid = true;
    $requiredFields.each(function() {
      var $input = $(this);
      var $feedback = $input.next('.error');
      if (!$input.val().trim()) {
        isValid = false;
        $input.addClass('is-invalid');
        $(this).focus();
        $(window).scrollTop($input.offset().top - 100);
        $feedback.text('This field is required.');
        return false;
      } else {
        $feedback.text('');
        $input.removeClass('is-invalid');
      }
    });
    if (isValid) {
      $('.school_find').addClass('hidden');
      $('#schoolNotFoundModal').addClass('hidden');
      $('.modal-backdrop').remove();
      $('.company_invitation').removeClass('hidden');
    }
  });

  $('.roles_onboard_btn').click(function(e){
    e.preventDefault();
    $('.school_find').addClass('hidden');
    $('#schoolNotFoundModal').addClass('hidden');
    $('.modal-backdrop').remove();
    $('.company_invitation').removeClass('hidden');
  });

  $(document).on('click', '.skip_company_invite', function(e){
    e.preventDefault();
    $('#user_invitation_email').val('');
    var form = document.getElementById('new_company_user');
    form.submit();
  });

  $('.signup_next').unbind().click(()=>{
    if (jquery('#new_user').valid()) {
      $('.user_form_top').addClass('hidden');
      $('.user_type_container').removeClass('hidden');
    }
  });

  // $('.user_type_radio').click(function(){
  //   $('.onboarding-for-student').addClass('hidden');
  //   $('.onboarding_user_race_ethnicity').removeClass('hidden')
  // });
});
