import consumer from "./consumer";
$(document).on('turbolinks:load', function() {
var conversation_body;
conversation_body = $('#webinar-group-conversation');
if (conversation_body.length > 0) {
const webinarChannel = consumer.subscriptions.create({ channel: "WebinarChannel", webinar_id: conversation_body.data('webinar-id') }, {
  connected: function() {
  },

  disconnected: function() {
  },

  received: function(data) {
    switch (data.type) {
      case 'participant_removed':
        handleParticipantRemoved(data.userId);
        var userElement = $('.webinar_room_action.hand[data-participant-id="' + data.userId + '"]');
        userElement.show();
        const currentUserID = $('[data-controller="vonage"]').data('vonage-speaker');
        if (currentUserID === parseInt(data.userId) ){
          const modal = $('#deniedUserRequestModal');
          modal.find('#modal_user_text').html(`The moderator(s) have not added you as a speaker.`);
          jquery('#deniedUserRequestModal').modal('show');
        }
        break;
      case 'participant_approved':
        handleParticipantRemoved(data.userId);
        const speakerId = data.userId;
        const participantExists = $(`#webinar_room_speakers [data-participant-id="${speakerId}"]`).length > 0;

        if (!participantExists) {
          const newParticipant = document.createElement("a");
          newParticipant.href = "#";
          newParticipant.setAttribute("data-user-id", data.userId);

          $.ajax({
            url: '/webinars/checkrequesteduserinspeakers',
            method: 'POST',
            data: {
              participant_id: data.userId,
              slug: data.webinarID
            },
            success: function (response) {
              if (response.match) {
                window.location.reload();
              } else {
                 const participantHtmlTemplate = `
                  <span class="d-flex webinar_room_speakers_item" data-participant-id="${speakerId}">
                    <span class="d-flex webinar_room_speakers_item_left">
                      <img class="avatar-img" src="${data.participantImage}" alt="Participant Image">
                      <span class="d-inline-block webinar_room_speakers_item_name">
                        ${data.participantName}
                        <a class="webinar_room_speakers_add_link" style="color: #004EEB;">
                          Speaker
                        </a>
                      </span>
                    </span>
                    <span class="d-flex webinar_room_speakers_item_right">
                      ${response.status ? 
                        `<a href="#" class="webinar_room_speakers_item_action mic_on user_mic_${speakerId}" id="mic_users" remote="true"></a>
                         <a href="#" class="webinar_room_speakers_item_action cam_on user_camera_${speakerId}" id="camera_users" remote="true"></a>` 
                        : 
                        `<strong><a class="webinar_room_speakers_item_action" style="color: #004EEB;" onclick="openChatSection()">Chat</a></strong>`}
                    </span>
                  </span>
                `;
                newParticipant.innerHTML = participantHtmlTemplate;
                $("#webinar_room_speaker_user1").append(newParticipant);
              }
            },
            error: function (error) {
              console.error('Error checking user match:', error);
            }
          });
        }
        break;

      case 'start_webinar':
        window.location.reload();
        break;
      case 'pause_webinar':
        window.location.reload();
        break;
      case 'end_webinar':
        window.location.reload();
        break;
      case 'start_recording':
        jquery('#recording_modal').modal('show');
        break;

      default:
        const participantId = data.participantId;
        const vonageSpeaker = $('[data-controller="vonage"]').data('vonage-speaker');
        const webinar_speakers = data.moderator_speakers
        const isWebinarSpeaker = webinar_speakers.includes(vonageSpeaker);

        if (!$(`#webinar_room_ask_to_speak [data-participant-id="${participantId}"]`).length) {
          const newParticipant = document.createElement("a");
          newParticipant.href = "#";
          newParticipant.setAttribute("data-toggle", "modal");
          newParticipant.setAttribute("data-target", "#recieveRequestModal");
          newParticipant.setAttribute("data-user-id", data.participantId);

          const newParticipantHtml = `
            <span class="d-flex webinar_room_speakers_item" data-participant-id="${participantId}">
              <span class="d-flex webinar_room_speakers_item_left">
                <img class="avatar-img" src="${data.participantImage}" alt="Participant Image">
                <span class="d-inline-block webinar_room_speakers_item_name">
                  ${data.participantName}
                </span>
              </span>
              <span class="d-flex webinar_room_speakers_item_right">
                <a href="#" class="webinar_room_speakers_item_action mic_on user_mic_${participantId}" id="mic_users" remote="true"></a>
                <a href="#" class="webinar_room_speakers_item_action cam_off user_camera_${participantId}" id="camera_users" remote="true"></a>
              </span>
            </span>
          `;

          newParticipant.innerHTML = newParticipantHtml;

          $("#webinar_room_ask_to_speak").append(newParticipant);
          $('#recieveRequestModal').data('participant-id', participantId);

          if (isWebinarSpeaker) {
          
          const participantName = data.participantName;
          const picture_url = data.participantImage;

          const modal = $('#recieveRequestModal')
          modal.find('#modal_user_text').html(`<strong>${participantName}</strong> requests to speak ras sed sed sed mi sit dolor tortor. Enim ut volutpat felis eget maecenas nibh lacinia justo odio.`);
          modal.find('#modal_user_image').attr('src', picture_url);

          jquery('#recieveRequestModal').modal('show')
          }
        }
        break;
    }
    if ($('#webinar_room_ask_to_speak').children().length > 0) {
      $('#moderator_webinar_room_speakers_top').removeClass('hide-important');
    } else {
      $('#moderator_webinar_room_speakers_top').addClass('hide-important');
    }
  },
});

function handleParticipantRemoved(userId) {
  $(`.webinar_room_speakers_items_container [data-participant-id="${userId}"]`).remove()
  $(`a[data-user-id="${userId}"]`).remove();
}

$("#confirmRequestBtn").on("click", function (e) {
  e.preventDefault();

  $.ajax({
    url: "/webinars/addusertoasktospeak",
    type: "POST",
    dataType: "json",
    success: function (data) {
      webinarChannel.send_message('', '', '', '', '', '', '', data.participantName);
    },
    error: function (error) {
      console.error("Error adding participant:", error);
    }
  });
});

$("#confirmNoRequestBtn").on("click", function (e) {
  e.preventDefault();

  $.ajax({
    url: "/webinars/removeuserfromasktospeak",
    type: "POST",
    dataType: "json",
    success: function (data) {
      webinarChannel.send_message('', '', '', '', '', '', '', '');
    },
    error: function (error) {
      console.error("Error adding participant:", error);
    }
  });
});
}
});